<template>
  <footer class="text-center text-white opacity-75">
    <div class="flex flex-wrap px-[30px] justify-between text-white items-center">
      <img alt="Hero logo" src="../assets/img/logo_white.png" class="max-w-full max-h-full h-14">
      <span class="py-6 font-MontserratMedium text-xs"><a href="/">Hero Studio </a>© <br> All rights reserved</span>
    </div>
  </footer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "test.vue"
}
</script>

<style scoped>

</style>
