<template>
<!--  <video autoplay muted loop id="myVideo" style="object-fit: cover;-->
<!--  width: 100vw;-->
<!--  height: 100vh;-->
<!--  position: fixed;-->
<!--  top: 0;-->
<!--  left: 0;-->
<!--  z-index: 1;-->
<!--">-->
<!--    <source src="./assets/Homepage-Showreel.mp4" type="video/mp4">-->
<!--    Your browser does not support HTML5 video.-->
<!--  </video>-->

<!--  <div class="video-container">-->
<!--    <video autoplay muted loop>-->
<!--      <source src="./assets/Homepage-Showreel.mp4" type="video/mp4" />-->
<!--    </video>-->
<!--      <nav class="w-full relative" style="z-index: 2">-->
<!--        <div class="flex relative">-->
<!--          <router-link to="/" class="w-1/3">-->
<!--            <img alt="Vue logo" src="./assets/img/logo_black.png" class="max-w-full max-h-full w-1/3">-->
<!--          </router-link>-->
<!--          <div class="flex items-center justify-center w-1/3">-->
<!--            <span class="text-sm w-1/3">CREATIVE AGENCY BASED IN TIRANA <br>SINCE 2018.</span>-->

<!--          </div>-->
<!--          <div class="flex space-x-4 items-center justify-end w-1/3">-->
<!--            <router-link to="/portofolio">Portofolio</router-link>-->
<!--            <router-link to="/about">About</router-link>-->
<!--            <router-link to="/contact">Contact</router-link>-->
<!--          </div>-->
<!--        </div>-->
<!--      </nav>-->
<!--  </div>-->
  <router-view/>
</template>

<script>
export default {
}
</script>

<style>
.video-container {
  height: 100vh;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
