<template>
  <div>
    <nav class="w-full" style="z-index: 2">
      <div class="flex relative justify-between items-center font-MontserratMedium">
        <a href="/" class="w-1/4">
          <img alt="Hero Studio Logo" src="../assets/img/logo_black.png" class="hidden sm:flex max-w-full max-h-full sm:w-1/3">
          <img alt="Hero Studio Logo" src="../assets/img/logo_white.png" class="sm:hidden max-w-full max-h-full sm:w-1/3">
        </a>
        <svg v-if="!showNavbar" class="md:hidden" @click="showNavbar =! showNavbar" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
          <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
	<rect x="0" y="13.64" rx="0" ry="0" width="90" height="8" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) "/>
            <rect x="0" y="41" rx="0" ry="0" width="90" height="8" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) "/>
            <rect x="0" y="68.36" rx="0" ry="0" width="90" height="8" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) "/>
</g>
</svg>
        <svg v-if="showNavbar" @click="showNavbar =! showNavbar" xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="fill-white opacity-75" viewBox="0 0 352 512"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>

<!--        <div class="md:flex hidden items-center justify-center w-1/3">-->
<!--          <a class="text-sm w-1/3">CREATIVE AGENCY BASED IN TIRANA SINCE 2018</a>-->
<!--        </div>-->
        <div class="md:flex md:inline-flex space-x-4 items-center justify-end w-1/3 hidden">
          <router-link to="/portfolio" class="hover:text-white">Portfolio</router-link>
          <router-link to="/about" class="hover:text-white">About</router-link>
          <a @click="this.$router.replace({ path: '/contact' })"
             class="font-bold cursor-pointer hover:text-white" :class="{ 'router-link-active router-link-exact-active' : this.$route.name === 'contact'}">
            Contact
          </a>
        </div>
      </div>
      <div :class="showNavbar ? 'flex flex-col' : 'hidden' " class="items-center ml-2 justify-end w-full md:hidden">
        <a v-if="this.$route.name !== 'home'" href="/" class="py-2">Home</a>
        <a href="/portfolio" class="py-2">Portfolio</a>
        <router-link to="/about" class="py-2">About</router-link>
        <router-link to="/contact" class="py-2">Contact</router-link>
      </div>
<!--      <span class="text-sm w-1/3 md:hidden text-black">CREATIVE AGENCY BASED IN TIRANA SINCE 2018</span>-->

      <hr>
    </nav>

  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Navbar',
  data() {
    return {
      showNavbar: false,
    }
  },
  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
  },
}
</script>
<style>
nav a.router-link-exact-active {
  @apply border-b-4 border-gray-100
}
</style>

