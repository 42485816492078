<template>
<div>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 h-screen overflow-y-auto text-center">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75" />
      </div>
      <div class="inline-block align-bottom w-full h-screen bg-[#0f0f0f] rounded-lg text-left shadow-xl transform transition-all sm:align-middle">
        <button @click="$emit('closeVideoPlay')" class="flex w-full justify-end p-7 md:px-14">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="fill-white" version="1.1" id="mdi-close-thick" width="24" height="24" viewBox="0 0 24 24"><path d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z" fill=""></path></svg>
        </button>

        <div class="px-7 md:px-14">
          <iframe title="vimeo-player" class="w-full" :src="videoSrc" width="840" height="560" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </transition>
</div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "VideoPlay",
  data: () => ({
    scrollPosition: 50,
  }),
  props: {
    videoSrc: {
      type: String,
      required: true,
      default: 'https://player.vimeo.com/video/786717967?h=490b884758'
    }
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  }
}
</script>
